<template>
  <div class="payment-duedates-table payment-duedates">
    <!-- HEADER PER DESKTOP -->
    <v-row no-gutters class="table-header text-uppercase hidden-xs-only">
      <v-col cols="2" class="text-center">
        {{ $t("paymentDueDate.header.status") }}
      </v-col>
      <v-col cols="1" class="text-center">
        {{ $t("paymentDueDate.header.docDate") }}
      </v-col>
      <v-col cols="2" class="text-center">
        {{ $t("paymentDueDate.header.docReferenceNumber") }}
      </v-col>
      <v-col cols="1" class="text-center">
        {{ $t("paymentDueDate.header.dueDate") }}
      </v-col>
      <v-col cols="2" class="text-center">
        {{ $t("paymentDueDate.header.docAmount") }}
      </v-col>
      <v-col cols="2" class="text-center">
        {{ $t("paymentDueDate.header.paymentAmount") }}
      </v-col>
      <v-col cols="2" class="text-center">
        {{ $t("paymentDueDate.header.paymentMethod") }}
      </v-col>
    </v-row>

    <!-- LISTA PER DESKTOP -->
    <v-list elevation="0" class="rows-list hidden-xs-only">
      <v-list-item
        v-for="(invoice, index) in duedateList"
        :key="invoice.paymentDueDateId"
        :class="{ zebra: index % 2 == 0 }"
      >
        <v-row class="row-card" no-gutters align="center">
          <v-col cols="2">
            <v-checkbox
              hide-details
              v-model="invoice.selected"
              :value="false"
              :disabled="
                invoice.docAmount < 0 || invoice.paymentDueDateStatusId !== 1
              "
              @click="update"
            >
              <template v-slot:label>
                <span
                  class="font-weight-bold"
                  :class="'status' + invoice.paymentDueDateStatusId"
                  >{{ getStatus(invoice) }}</span
                >
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="1" class="text-center">
            {{
              invoice.docDate
                | formatDate("fromFormat", "yyyy-MM-dd", "dd/MM/yyyy")
            }}
          </v-col>
          <v-col cols="2" class="text-center">
            {{ invoice.docReferenceNumber }}
          </v-col>
          <v-col cols="1" class="text-center">
            {{
              invoice.dueDate
                | formatDate("fromFormat", "yyyy-MM-dd", "dd/MM/yyyy")
            }}
          </v-col>
          <v-col cols="2" class="text-center">
            {{ $n(invoice.docAmount, "currency") }}
          </v-col>
          <v-col cols="2" class="text-center font-weight-bold">
            {{ $n(invoice.paymentAmount, "currency") }}
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="payment-method">
              {{ invoice.paymentMethod.name }}
            </span>

            <v-tooltip
              v-if="invoice.paymentMethod.codInt === 'Y'"
              left
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="warning"
                  >mdi-information-outline</v-icon
                >
              </template>
              Piano di rientro
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>

    <!-- CONTENITORE DI CARD PER MOBILE (smartphone) -->
    <v-container class="invoice-cards hidden-sm-and-up">
      <v-card
        v-for="invoice in duedateList"
        class="card pa-0 mt-8"
        outlined
        :key="invoice.paymentDueDateId"
        flat
      >
        <v-row no-gutters class="pa-0">
          <v-col cols="6">
            <div class="header">{{ $t("paymentDueDate.header.status") }}</div>
            <v-checkbox
              hide-details
              v-model="invoice.selected"
              :value="false"
              :disabled="
                invoice.docAmount < 0 || invoice.paymentDueDateStatusId !== 1
              "
              @click="update"
            >
              <template v-slot:label>
                <span
                  class="font-weight-bold"
                  :class="'status' + invoice.paymentDueDateStatusId"
                  >{{ getStatus(invoice) }}</span
                >
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <div class="header">{{ $t("paymentDueDate.header.docDate") }}</div>
            <div>
              {{
                invoice.docDate
                  | formatDate("fromFormat", "yyyy-MM-dd", "dd LLLL yyyy")
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.header.docReferenceNumber") }}
            </div>
            <div>
              {{ invoice.docReferenceNumber }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.header.dueDate") }}
            </div>
            <div>
              {{
                invoice.dueDate
                  | formatDate("fromFormat", "yyyy-MM-dd", "dd LLLL yyyy")
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.header.docAmount") }}
            </div>
            <div>
              {{ $n(invoice.docAmount, "currency") }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.header.paymentAmount") }}
            </div>
            <div>{{ $n(invoice.paymentAmount, "currency") }}</div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.header.paymentMethod") }}
            </div>
            <span class="payment-method">
              {{ invoice.paymentMethod.name }}
            </span>
            <span v-if="invoice.paymentMethod.codInt === 'Y'">
              Piano di rientro
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <!-- TOTALI E PULSANTI PAGAMENTO -->
    <v-container fluid class="mt-12">
      <v-row no-gutters justify="center" class="mb-4">
        <v-col cols="6" sm="4" class="text-right">
          <h3>{{ $t("paymentDueDate.totalAmount") }}</h3>
        </v-col>
        <v-col cols="6" sm="4">
          <h3 class="total offset-2">{{ $n(totalAmount, "currency") }}</h3>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="6" sm="4" class="text-right">
          <h3>{{ $t("paymentDueDate.totalSelectedAmount") }}</h3>
        </v-col>
        <v-col cols="6" sm="4">
          <h3 class="total offset-2">
            {{ $n(totalSelectedAmount, "currency") }}
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-btn
          elevation="0"
          :disabled="totalSelectedAmount <= 0"
          min-width="120"
          class="primary mx-4 mb-4 mb-sm-0"
          @click="pay(12)"
          >{{ $t("paymentDueDate.button.payWithCC") }}</v-btn
        >
        <v-btn
          elevation="0"
          :disabled="totalSelectedAmount <= 0"
          min-width="120"
          class="primary mx-4"
          @click="pay(17)"
          >{{ $t("paymentDueDate.button.payWithTransfer") }}</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PaymentDueDatesService from "~/service/paymentDueDatesService";
export default {
  props: ["duedateList"],
  data() {
    return {
      totalSelectedAmount: 0
    };
  },
  computed: {
    totalAmount() {
      let amt = 0;
      for (let x = 0; x < this.duedateList.length; x++) {
        amt += this.duedateList[x].paymentAmount;
      }
      return amt;
    }
  },
  methods: {
    update() {
      this.totalSelectedAmount = 0;
      for (let x = 0; x < this.duedateList.length; x++) {
        if (
          this.duedateList[x].selected &&
          this.duedateList[x].paymentDueDateStatusId === 1
        ) {
          this.totalSelectedAmount += this.duedateList[x].paymentAmount;
        }
      }
    },
    getStatus(invoice) {
      if (invoice.paymentDueDateStatusId == 1) {
        if (new Date(invoice.dueDate) <= new Date()) {
          invoice.statusID = 1;
          return this.$t("paymentDueDate.status.expired");
        } else {
          invoice.statusID = 2;
          return this.$t("paymentDueDate.status.expiring");
        }
      }
      if (invoice.paymentDueDateStatusId == 2) {
        invoice.statusID = 3;
        return this.$t("paymentDueDate.status.pending");
      }
      if (invoice.paymentDueDateStatusId == 3) {
        invoice.statusID = 4;
        return this.$t("paymentDueDate.status.paid");
      }
    },
    pay(paytype) {
      let selected = [];
      let payments = [paytype];
      for (let x = 0; x < this.duedateList.length; x++) {
        if (
          this.duedateList[x].selected == true &&
          this.duedateList[x].paymentDueDateStatusId == 1
        ) {
          selected.push(this.duedateList[x].paymentDueDateId);
        }
      }
      let paymentData = null;
      if (paytype === 17) {
        paymentData = { mybank: true };
      }
      this.loading = true;
      let that = this;
      PaymentDueDatesService.duedatePay(
        selected,
        payments,
        null,
        paymentData
      ).then(
        function(response) {
          that.$emit("reload");
          window.location.href = response.data.paymentUrl;
          that.loading = false;
        },
        function() {
          global.EventBus.$emit("error", {
            error: that.$t("paymentDueDate.error.title"),
            message: that.$t("paymentDueDate.error.text")
          });
          that.loading = false;
        }
      );
    }
  }
};
</script>
