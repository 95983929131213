<template>
  <div class="payment-transactions-table">
    <!-- HEADER PER DESKTOP -->
    <v-row no-gutters class="table-header text-uppercase hidden-xs-only">
      <v-col cols="2">
        {{ $t("paymentDueDate.transactions.header.orderTransactionId") }}
      </v-col>
      <v-col cols="2" class="text-align-center">
        {{ $t("paymentDueDate.transactions.header.addDate") }}
      </v-col>
      <v-col cols="3" md="3" class="text-align-center">
        {{ $t("paymentDueDate.transactions.header.transactionStatusId") }}
      </v-col>
      <v-col cols="3" md="3" class="text-align-center">
        {{ $t("paymentDueDate.transactions.header.paymentTypeId") }}
      </v-col>
      <v-col cols="2" md="2" class="text-align-center">
        {{ $t("paymentDueDate.transactions.header.authorizedAmount") }}
      </v-col>
    </v-row>

    <!-- LISTA PER DESKTOP -->
    <v-list elevation="0" class="rows-list hidden-xs-only">
      <v-list-item
        v-for="(transaction, index) in transactionList"
        :key="transaction.orderTransactionId"
        :class="{ zebra: index % 2 == 0 }"
      >
        <v-row class="row-card" no-gutters align="center">
          <v-col cols="2" class="font-weight-bold">
            {{ transaction.orderTransactionId }}
          </v-col>
          <v-col cols="2" class="text-align-center">
            {{
              transaction.addDate
                | formatDate("fromFormat", "yyyy-MM-dd", "dd LLLL yyyy")
            }}
          </v-col>
          <v-col cols="3" class="text-align-center"
            ><span
              :class="
                'payment-transaction-status-' + transaction.transactionStatusId
              "
            >
              {{
                $t(
                  "paymentDueDate.transactions.status" +
                    "." +
                    transaction.transactionStatusId
                )
              }}
            </span>
          </v-col>
          <v-col cols="3" class="text-align-center">
            {{
              $t(
                "paymentDueDate.transactions.paymentType" +
                  "." +
                  transaction.paymentTypeId
              )
            }}
          </v-col>
          <v-col cols="2" class="font-weight-bold text-align-center">
            {{ $n(transaction.authorizedAmount, "currency") }}
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>

    <!-- CONTENITORE DI CARD PER MOBILE (smartphone) -->
    <v-container class="transaction-cards hidden-sm-and-up">
      <v-card
        v-for="transaction in transactionList"
        :key="transaction.orderTransactionId"
        class="card pa-0 mt-8"
        outlined
        flat
      >
        <v-row no-gutters class="pa-0">
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.transactions.header.orderTransactionId") }}
            </div>
            <div class="font-weight-bold">
              {{ transaction.orderTransactionId }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.transactions.header.addDate") }}
            </div>
            <div>
              {{
                transaction.addDate
                  | formatDate("fromFormat", "yyyy-MM-dd", "dd LLLL yyyy")
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.transactions.header.transactionStatusId") }}
            </div>
            <div
              :class="
                'payment-transaction-status-' + transaction.transactionStatusId
              "
            >
              {{
                $t(
                  "paymentDueDate.transactions.status" +
                    "." +
                    transaction.transactionStatusId
                )
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.transactions.header.paymentTypeId") }}
            </div>
            <div>
              {{
                $t(
                  "paymentDueDate.transactions.paymentType" +
                    "." +
                    transaction.paymentTypeId
                )
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="header">
              {{ $t("paymentDueDate.transactions.header.authorizedAmount") }}
            </div>
            <div class="font-weight-bold">
              {{ $n(transaction.authorizedAmount, "currency") }}
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["transactionList"]
};
</script>
